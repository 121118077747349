<template>
    <div>
        <div class="w-100">
            <Header title="" />
        </div>

        <div class="w-100 d-flex flex-row flex-wrap">
            <left-side-bar />
            <div class="ml-2" style="width: 90vw">
                <div
                    class="
                        d-flex
                        flex-row flex-wrap
                        justify-between
                        align-items-center
                        w-100
                    "
                >
                    <h1 class="font-x-large font-weight-bold">Usuarios</h1>
                    <el-button
                        class="pull-end bg-yellow font-12 font-weight-bold"
                        style="float: right"
                        round
                        @click="$emit('newUser')"
                    >
                        NUEVO USUARIO
                    </el-button>
                </div>
                <div>
                    <el-tabs
                        @tab-click="onTabChanged"
                        v-model="activeName"
                        class="w-100"
                    >
                        <el-tab-pane label="Delivery" name="first">
                        </el-tab-pane>
                        <el-tab-pane label="Verificador" name="second">
                        </el-tab-pane>
                        <el-tab-pane label="Empacador" name="third">
                        </el-tab-pane>
                    </el-tabs>
                    <tab-user-list @editUser="rowClicked" :tab-role="tabRole" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/dashboard/include/Header";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import moment from "moment";
import "moment/locale/es-do";
import _service from "@/services/userService";
import TabUserList from "./tabUserList.vue";
export default defineComponent({
    name: "Users",
    components: { Header, TabUserList, leftSideBar },

    data() {
        return {
            tabRole: "",
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            role: "Delivery",
            tableData: [],
            multipleSelection: [],
        };
    },
    created() {
        this.getAllUsers(10, 1, "Delivery", this.searchByquery);
        this.moment = moment;
    },
    mounted() {
        this.tabRole = "Delivery";
        this.moment.locale("es-do");
    },
    methods: {
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        onTabChanged(name) {
            this.tabRole = name.props.label;
        },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
                Role: this.role,
            };
            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        rowClicked(row) {
            this.$emit("editUser", row);
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        searchByQuery() {
            const data = {
                Query: this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
                Role: this.role,
            };

            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        getAllUsers(page_size, page_index, role) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
                Role: role,
            };
            _service.getUsers(data).then((data) => {
                this.total_pages = data.data.totalPages;
                this.total_count = data.data.pageSize;
                this.tableData = data.data.items;
            });
        },
    },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
</style>
