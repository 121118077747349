<template>
    <div>
        <h3 style="font-size: 21px; font-weight: bold">{{ tabRole }}</h3>
        <el-row :gutter="20">
            <el-col :span="8">
                <div style="width: 350px">
                    <el-input v-model="searchByquery" placeholder="Buscar">
                        <template style="color: white" #append>
                            <el-button
                                style="background-color: #3f69a6"
                                @click="searchByQuery()"
                                type="primary"
                            >
                                <i
                                    style="color: white"
                                    class="el-icon-search el-icon-center"
                                ></i>
                            </el-button>
                        </template>
                    </el-input>
                </div>
            </el-col>
            <el-col :span="4">
                <div>
                    <el-button size="mini" icon="el-icon-filter">
                        <i
                            style="color: black"
                            class="el-icon-filter el-icon-center"
                        ></i
                    ></el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button
                        style="
                            background-color: transparent;
                            border-color: transparent;
                        "
                        type="primary"
                        size="mini"
                        icon="el-icon-delete"
                    ></el-button>
                </div>
            </el-col>
        </el-row>
        <br />
        <br />
        <br />
        <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; max-width: 90vw"
            @selection-change="handleSelectionChange"
            @row-click="rowClicked"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column
                label="Numeración"
                property="userNumber"
                width="220"
            />
            <el-table-column property="firstName" label="Nombre" width="200" />
            <el-table-column property="lastName" label="Apellido" width="200" />
            <el-table-column
                property="usertype"
                label="Tipo de usuario"
                width="200"
            />
            <el-table-column
                property="creationDate"
                label="Fecha de creación"
                width="200"
            >
                <template #default="scope">
                    {{
                        moment(scope.row.creationDate).format(
                            "ddd MMM DD, YYYY"
                        )
                    }}
                </template>
            </el-table-column>

            <el-table-column label="Estatus" column-key="status" width="220">
                <template #default="scope">
                    <el-switch
                        v-model="tableData[scope.$index].isActive"
                        active-color="#13ce66"
                        @change="onStatusChanged(tableData[scope.$index].id)"
                        inactive-color="#ff4949"
                    />
                </template>
            </el-table-column>
        </el-table>
        <br />
        <br />
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <div class="block">
                    <el-pagination
                        layout="prev, pager, next"
                        :page-size="page_size"
                        :page-count="total_pages"
                        :total="total_count"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import moment from "moment";

import _service from "@/services/userService";
import userService from "../../../services/userService";
export default defineComponent({
    name: "Users",
    components: {},
    data() {
        return {
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            tableData: [],
            multipleSelection: [],
            loading: true,
        };
    },
    props: {
        tabRole: {
            type: String,
            default: "",
        },
    },
    watch: {
        tabRole(value) {
            if (value != "") {
                this.getAllUsers(
                    this.page_size,
                    1,
                    this.tabRole,
                    this.searchByquery
                );
                this.moment = moment;
            }
        },
    },
    methods: {
        async onStatusChanged(id) {
            await userService
                .toogleActivate(id)
                .then()
                .catch((error) => console.error(error));
        },
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
                Role: this.tabRole,
            };
            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        rowClicked(row, column) {
            if (column.columnKey != "status") {
                this.$emit("editUser", row.id);
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        searchByQuery() {
            const data = {
                Query: this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
                Role: this.tabRole,
            };

            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        getAllUsers(page_size, page_index, role) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
                Role: role,
            };
            this.loading = true;
            _service
                .getUsers(data)
                .then((data) => {
                    this.total_pages = data.data.totalPages;
                    this.total_count = data.data.pageSize;
                    this.tableData = data.data.items;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
</style>
