<template>
    <div class="home">
        <user-list
            @newUser="addNewUser()"
            :key="userComponentKey"
            @editUser="editUser"
        />
        <manage-user
            :edit-mode="editMode"
            :is-active="drawer"
            :user-id="userId"
            @drawerclosed="drawer = false"
            @refreshData="refreshData"
        />
    </div>
</template>
<script>
// @ is an alias to /src
// import api from "../services/userService";
import manageUser from "./components/manageUser.vue";
import userList from "./components/userList.vue";

export default {
    name: "Home",
    data() {
        return {
            drawer: false,
            editMode: false,
            userId: null,
            userComponentKey: 0,
        };
    },
    components: {
        manageUser,
        userList,
    },
    methods: {
        addNewUser() {
            this.editMode = false;
            this.userId = null;
            this.drawer = true;
        },
        refreshData() {
            this.userComponentKey += 1;
            this.drawer = false;
        },
        editUser(id) {
            this.editMode = true;
            this.userId = id;
            this.drawer = true;
        },
    },
    async mounted() {
        // let users = await api.getUsers({
        //     pageSize: 1,
        //     pageIndex: 1,
        //     role: "delivery",
        // });
    },
};
</script>
